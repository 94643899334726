import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTheme } from '../../../hooks/useTheme';
import { THEME_ICONS, ROUTES } from './constants';
import { isActiveRoute } from '../../../utils/routeUtils';
import DropdownMenu from './DropdownMenu';
import {
  Nav,
  NavContainer,
  Logo,
  NavLinks,
  NavLink,
  ThemeToggle,
  MobileMenuButton,
  MobileMenuButtonLine,
  MobileMenu,
  MobileNavLink,
} from './styles';

const Navigation: React.FC = () => {
  const location = useLocation();
  const { themeMode, toggleTheme } = useTheme();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isProjectsMenuOpen, setProjectsMenuOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  const projects = useMemo(() => [
    { id: 'neural-network-visualization', name: 'Neural Network Visualization' },
    { id: 'midjourney-masterclass', name: 'Midjourney MasterClass' },
    { id: 'mtar-framework-visualization', name: 'MTAR Framework Visualization' },
  ], []);

  const handleMobileMenuToggle = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
  }, []);

  const handleScroll = useCallback(() => {
    if (navRef.current) {
      navRef.current.classList.toggle('scrolled', window.scrollY > 50);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setMobileMenuOpen(false);
        setProjectsMenuOpen(false);
      }
    };
    document.addEventListener('keydown', handleEscKey);
    return () => document.removeEventListener('keydown', handleEscKey);
  }, []);

  return (
    <Nav ref={navRef}>
      <NavContainer>
        <Logo to="/" onClick={() => setMobileMenuOpen(false)}>MEGAPROMPT.IO </Logo>
        <NavLinks>
          {ROUTES.map((route) => (
            <NavLink 
              key={route.path}
              to={route.path} 
              $isActive={isActiveRoute(location.pathname, route.path, route.path === '/')}
              aria-current={isActiveRoute(location.pathname, route.path, route.path === '/') ? 'page' : undefined}
            >
              {route.label}
            </NavLink>
          ))}
          <NavLink 
            to="/projects" 
            $isActive={isActiveRoute(location.pathname, '/projects')}
            onMouseEnter={() => setProjectsMenuOpen(true)}
            onMouseLeave={() => setProjectsMenuOpen(false)}
            aria-haspopup="true"
            aria-expanded={isProjectsMenuOpen}
          >
            Projects
            <DropdownMenu 
              isOpen={isProjectsMenuOpen}
              projects={projects}
              onMouseEnter={() => setProjectsMenuOpen(true)}
              onMouseLeave={() => setProjectsMenuOpen(false)}
            />
          </NavLink>
        </NavLinks>
        <ThemeToggle onClick={toggleTheme} aria-label="Toggle theme">
          {THEME_ICONS[themeMode]}
        </ThemeToggle>
        <MobileMenuButton 
          onClick={handleMobileMenuToggle} 
          aria-expanded={isMobileMenuOpen} 
          aria-controls="mobile-menu"
          aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
        >
          <MobileMenuButtonLine />
          <MobileMenuButtonLine />
          <MobileMenuButtonLine />
        </MobileMenuButton>
        <MobileMenu id="mobile-menu" $isOpen={isMobileMenuOpen}>
          {ROUTES.map((route) => (
            <MobileNavLink 
              key={route.path}
              to={route.path} 
              onClick={handleMobileMenuToggle}
              $isActive={isActiveRoute(location.pathname, route.path, route.path === '/')}
            >
              {route.label}
            </MobileNavLink>
          ))}
          <MobileNavLink to="/projects" onClick={handleMobileMenuToggle}>Projects</MobileNavLink>
        </MobileMenu>
      </NavContainer>
    </Nav>
  );
};

export default memo(Navigation);