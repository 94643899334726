import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import axios from 'axios';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import {
  FaPaperclip,
  FaPaperPlane,
  FaRobot,
  FaTimes,
} from 'react-icons/fa';
import io from 'socket.io-client';
import styled from 'styled-components';

import { useGlobalState } from '../../contexts/GlobalStateContext';

const ChatWrapper = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  max-width: 80%;
  ${({ isUser }) => (isUser ? `
    align-self: flex-end;
    background-color: var(--primary-color);
    color: white;
  ` : `
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
  `)}
`;

const ChatInput = styled.form`
  display: flex;
  padding: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 5px;
  border: none;
  border-radius: 5px 0 0 5px;
`;

const SendButton = styled.button`
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
`;

const TypingIndicator = styled.div`
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  color: black;
  align-self: flex-start;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  padding: 5px 10px;
  text-align: center;
`;

const MAX_CHAR_LIMIT = 200;

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null);
  const { state: { user } } = useGlobalState();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const fetchAIResponse = async (prompt) => {
    try {
      const response = await axios.post(process.env.REACT_APP_OPENAI_API_URL, {
        prompt,
        max_tokens: 150
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        }
      });

      return response.data.choices[0].text.trim();
    } catch (err) {
      console.error('Error fetching AI response:', err);
      setError('Failed to fetch response from AI.');
    }
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (input.trim() && user) {
      const newMessage = { text: input, isUser: true, userId: user.id };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      setError(null);

      const aiResponse = await fetchAIResponse(input);

      if (aiResponse) {
        setMessages((prevMessages) => [...prevMessages, { text: aiResponse, isUser: false }]);
      }

      setIsTyping(false);
    }
  }, [input, user]);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file && user) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        socketRef.current.emit('file', { file: fileData, fileName: file.name, userId: user.id });
      };
      reader.readAsDataURL(file);
    }
  }, [user]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    if (socketUrl) {
      socketRef.current = io(socketUrl);

      socketRef.current.on('connect', () => {
        console.log('Connected to server');
      });

      socketRef.current.on('message', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        setIsTyping(false);
      });

      socketRef.current.on('typing', () => {
        setIsTyping(true);
      });

      socketRef.current.on('error', (error) => {
        setError(error);
        setIsTyping(false);
      });

      return () => {
        socketRef.current.disconnect();
      };
    } else {
      console.error('Socket URL is not defined');
    }
  }, []);

  const toggleChat = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <ChatWrapper
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            role="dialog"
            aria-labelledby="chat-header"
          >
            <ChatHeader id="chat-header">
              <FaRobot style={{ marginRight: '10px' }} aria-hidden="true" /> MEGABOT {/* Change name to MEGABOT */}
              <FaTimes onClick={toggleChat} style={{ cursor: 'pointer' }} aria-label="Close chat" />
            </ChatHeader>
            <ChatMessages role="log" aria-live="polite">
              {messages.map((message, index) => (
                <Message key={index} isUser={message.isUser}>
                  {message.text}
                </Message>
              ))}
              {isTyping && <TypingIndicator>MEGABOT is typing...</TypingIndicator>} {/* Change name to MEGABOT */}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <div ref={messagesEndRef} />
            </ChatMessages>
            <ChatInput onSubmit={handleSubmit}>
              <FileInput 
                type="file" 
                id="file" 
                onChange={handleFileUpload} 
                accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
              />
              <FileLabel htmlFor="file">
                <FaPaperclip aria-hidden="true" />
              </FileLabel>
              <Input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value.slice(0, MAX_CHAR_LIMIT))}
                placeholder="Type your message..."
                maxLength={MAX_CHAR_LIMIT}
                aria-label="Chat input"
              />
              <SendButton type="submit" disabled={isTyping} aria-label="Send message">
                <FaPaperPlane aria-hidden="true" />
              </SendButton>
            </ChatInput>
          </ChatWrapper>
        )}
      </AnimatePresence>
      {!isOpen && (
        <ToggleButton onClick={toggleChat} aria-expanded={isOpen} aria-controls="chat-wrapper">
          <FaRobot aria-hidden="true" />
        </ToggleButton>
      )}
    </>
  );
};

export default ChatComponent;
