// src/index.tsx
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import { GlobalStateProvider } from './contexts/GlobalStateContext';
import { ThemeProvider } from './contexts/ThemeProvider';
import LoadingScreen from './components/common/LoadingScreen';

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => (
  <div role="alert" style={{ padding: '20px', textAlign: 'center' }}>
    <h1>Oops! Something went wrong.</h1>
    <p>We're sorry for the inconvenience. Please try refreshing the page or contact support if the problem persists.</p>
    {process.env.NODE_ENV === 'development' && (
      <pre style={{ whiteSpace: 'pre-wrap', maxWidth: '800px', margin: '20px auto', textAlign: 'left' }}>
        {error.message}
        {'\n'}
        {error.stack}
      </pre>
    )}
  </div>
);

const Root: React.FC = () => (
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider>
        <GlobalStateProvider>
          <Suspense fallback={<LoadingScreen />}>
            <App />
          </Suspense>
        </GlobalStateProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Root />);
} else {
  console.error('Failed to find the root element');
}