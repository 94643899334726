import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import * as Sentry from "@sentry/react";
import GlobalStyle from './styles/GlobalStyle';
import Navigation from './components/common/Navigation/Navigation';
import Footer from './components/common/Footer';
import ErrorBoundary from './components/common/ErrorBoundary';
import BackgroundCanvas from './components/common/BackgroundCanvas';
import { ProjectProvider } from './contexts/ProjectContext';
import { ThemeProvider } from './contexts/ThemeProvider';
import LoadingScreen from './components/common/LoadingScreen';
import { HelmetProvider } from 'react-helmet-async';
import ChatComponent from './components/common/ChatComponent'; // Import ChatComponent

const LandingPage = lazy(() => import('./components/pages/LandingPage/LandingPage'));
const AboutSection = lazy(() => import('./components/pages/AboutSection'));
const ProjectsSection = lazy(() => import('./components/pages/ProjectsSection'));
const MidjourneyMasterClass = lazy(() => import('./components/projects/MidjourneyMasterClass'));
const NeuralNetworkVisualization = lazy(() => import('./components/projects/NeuralNetworkVisualization'));
const MTARFrameworkVisualization = lazy(() => import('./components/projects/mtar/MTARFrameworkVisualization'));
const GalleryPage = lazy(() => import('./components/pages/GalleryPage'));
const ContactSection = lazy(() => import('./components/ContactSection'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 60px; // Adjust based on your Navigation height
`;

const NotFoundPage: React.FC = () => (
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you're looking for doesn't exist.</p>
  </div>
);

const AnimatedRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/projects" element={<ProjectsSection />} />
        <Route path="/projects/neural-network-visualization" element={<NeuralNetworkVisualization />} />
        <Route path="/projects/midjourney-masterclass" element={<MidjourneyMasterClass />} />
        <Route path="/projects/mtar-framework-visualization" element={<MTARFrameworkVisualization />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AnimatePresence>
  );
};

const App: React.FC = () => {
  const handleError = useCallback((error: Error, errorInfo: React.ErrorInfo) => {
    console.error('Uncaught error:', error, errorInfo);
    Sentry.captureException(error, { extra: errorInfo });
  }, []);

  useEffect(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tracesSampleRate: 1.0,
    });
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <ProjectProvider>
          <Router>
            <ErrorBoundary onError={handleError} FallbackComponent={ErrorFallback}>
              <GlobalStyle />
              <BackgroundCanvas />
              <AppContainer>
                <Navigation />
                <MainContent>
                  <Suspense fallback={<LoadingScreen />}>
                    <AnimatedRoutes />
                  </Suspense>
                </MainContent>
                <Footer />
                <ChatComponent /> {/* Add ChatComponent here */}
              </AppContainer>
            </ErrorBoundary>
          </Router>
        </ProjectProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => (
  <div role="alert">
    <h1>Oops! Something went wrong.</h1>
    <pre>{error.message}</pre>
  </div>
);

export default React.memo(App);
