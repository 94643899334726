import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../contexts/GlobalStateContext';

// Styled components for canvas and buttons
const CanvasWrapper = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Button = styled.button`
  position: fixed;
  bottom: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary};
    outline: 2px solid ${({ theme }) => theme.colors.focus};
  }
`;

const ColorSchemeButton = styled(Button)`
  left: 20px;
`;

// Particle interface
interface Particle {
  x: number;
  y: number;
  radius: number;
  dx: number;
  dy: number;
  opacity: number;
}

// Color schemes for particles
const colorSchemes = {
  blue: ['52, 152, 219', '41, 128, 185', '52, 73, 94'],
  green: ['46, 204, 113', '39, 174, 96', '22, 160, 133'],
  custom: ['52, 152, 219', '46, 204, 113', '231, 76, 60'],
};

const BackgroundCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [colorScheme, setColorScheme] = useState<keyof typeof colorSchemes>('blue');
  const { state } = useGlobalState();

  const particlesRef = useRef<Particle[]>([]);
  const animationFrameIdRef = useRef<number | null>(null);

  // Update canvas dimensions on window resize
  const updateDimensions = useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  // Initialize particles
  const initializeParticles = useCallback(() => {
    const particleCount = Math.min(150, Math.floor(dimensions.width * dimensions.height / 20000));
    particlesRef.current = Array.from({ length: particleCount }, () => ({
      x: Math.random() * dimensions.width,
      y: Math.random() * dimensions.height,
      radius: Math.random() * 3 + 1,
      dx: (Math.random() - 0.5) * 1.5,
      dy: (Math.random() - 0.5) * 1.5,
      opacity: Math.random() * 0.5 + 0.5,
    }));
  }, [dimensions]);

  // Get color for particles
  const getColor = useCallback(
    (index: number, opacity: number) => {
      const colors = colorSchemes[colorScheme];
      return `rgba(${colors[index % 3]}, ${opacity})`;
    },
    [colorScheme]
  );

  // Draw a single particle
  const drawParticle = useCallback(
    (ctx: CanvasRenderingContext2D, particle: Particle, color: string) => {
      ctx.beginPath();
      const gradient = ctx.createRadialGradient(particle.x, particle.y, 0, particle.x, particle.y, particle.radius);
      gradient.addColorStop(0, color.replace(/[\d.]+\)$/, '0.5)'));
      gradient.addColorStop(1, color.replace(/[\d.]+\)$/, '0)'));
      ctx.fillStyle = gradient;
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fill();
      ctx.shadowBlur = 10;
      ctx.shadowColor = color;
    },
    []
  );

  // Handle collisions between particles
  const handleCollisions = useCallback(() => {
    for (let i = 0; i < particlesRef.current.length; i++) {
      const p1 = particlesRef.current[i];
      for (let j = i + 1; j < particlesRef.current.length; j++) {
        const p2 = particlesRef.current[j];
        const dx = p1.x - p2.x;
        const dy = p1.y - p2.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < p1.radius + p2.radius) {
          const angle = Math.atan2(dy, dx);
          const speed1 = Math.sqrt(p1.dx * p1.dx + p1.dy * p1.dy);
          const speed2 = Math.sqrt(p2.dx * p2.dx + p2.dy * p2.dy);

          p1.dx = speed2 * Math.cos(angle);
          p1.dy = speed2 * Math.sin(angle);
          p2.dx = speed1 * Math.cos(angle + Math.PI);
          p2.dy = speed1 * Math.sin(angle + Math.PI);
        }
      }
    }
  }, []);

  // Main draw function
  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    ctx.clearRect(0, 0, dimensions.width, dimensions.height);

    particlesRef.current.forEach((particle, i) => {
      particle.x += particle.dx;
      particle.y += particle.dy;

      if (particle.x < 0 || particle.x > dimensions.width) particle.dx *= -1;
      if (particle.y < 0 || particle.y > dimensions.height) particle.dy *= -1;

      const color = getColor(i, particle.opacity);
      drawParticle(ctx, particle, color);

      particlesRef.current.forEach((otherParticle, j) => {
        if (i !== j) {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < 100) {
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.strokeStyle = color.replace(/[\d.]+\)$/, '0.2)');
            ctx.lineWidth = 1;
            ctx.stroke();
          }
        }
      });
    });

    handleCollisions();
    animationFrameIdRef.current = requestAnimationFrame(draw);
  }, [dimensions, getColor, drawParticle, handleCollisions]);

  // Set up and clean up event listeners
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  // Initialize particles and start animation
  useEffect(() => {
    initializeParticles();
    draw();

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [dimensions, colorScheme, state.theme, initializeParticles, draw]);

  // Toggle color scheme
  const toggleColorScheme = useCallback(() => {
    setColorScheme((prevScheme) => {
      const schemes = Object.keys(colorSchemes) as Array<keyof typeof colorSchemes>;
      const currentIndex = schemes.indexOf(prevScheme);
      return schemes[(currentIndex + 1) % schemes.length];
    });
  }, []);

  return (
    <>
      <CanvasWrapper ref={canvasRef} width={dimensions.width} height={dimensions.height} aria-hidden="true" />
      <ColorSchemeButton onClick={toggleColorScheme} aria-label="Change Color Scheme">Change Color Scheme</ColorSchemeButton>
    </>
  );
};

export default BackgroundCanvas;
