// src/contexts/ProjectContext.tsx
import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import projectsData from '../data/projectsData.json';
import galleryData from '../data/galleryData.json';

export interface Project {
  id: string;
  title: string;
  description: string;
  image: string;
  category: string;
  tags: string[];
  features: string[];
  content: any;
}

export interface GalleryItem {
  id: number;
  src: string;
  title?: string;
  category: string;
  description?: string;
  comparisonSrc?: string;
}

interface ProjectContextType {
  projects: Project[];
  galleryItems: GalleryItem[];
  loading: boolean;
  error: string | null;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<ProjectContextType>({
    projects: [],
    galleryItems: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    try {
      setState(prevState => ({
        ...prevState,
        projects: projectsData.projects,
        galleryItems: galleryData,
        loading: false
      }));
    } catch (err) {
      setState(prevState => ({
        ...prevState,
        error: 'Failed to load project data',
        loading: false
      }));
    }
  }, []);

  return (
    <ProjectContext.Provider value={state}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};

export default ProjectContext;