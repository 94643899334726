import { DefaultTheme } from 'styled-components';

type Colors = {
  primary: string;
  secondary: string;
  background: string;
  text: string;
  error: string;
  textSecondary: string;
  border: string;
  disabled: string;
  buttonBackground: string;
  buttonText: string;
  buttonHoverBackground: string;
  buttonHoverText: string;
  focus: string;
  cardBackground: string;
  navBackground: string;
  primaryDark: string;
  errorBackground: string;
  accent: string; // Add accent color
};

const baseTheme = {
  fontWeights: {
    bold: 700,
    normal: 400,
  },
  fontSizes: {
    '5xl': '3rem',
    '4xl': '2.5rem',
    '3xl': '2rem',
    '2xl': '1.75rem',
    xl: '1.25rem',
    lg: '1rem',
    md: '1rem',
    sm: '0.875rem',
  },
  spacing: {
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    6: '1.5rem',
    8: '2rem',
  },
  borderRadius: {
    md: '0.375rem',
    default: '0.375rem',
  },
  transitions: {
    default: '0.3s',
  },
  breakpoints: {
    xl: '1200px',
    lg: '992px',
    md: '768px',
    sm: '576px',
  },
  lineHeights: {
    normal: '1.5',
    tight: '1.25',
  },
  sizes: {
    maxWidth: '1200px',
  },
  zIndex: {
    dropdown: 1000,
    sticky: 1020,
    fixed: 1030,
    modal: 1050,
    popover: 1060,
    tooltip: 1070,
  },
};

const lightColors: Colors = {
  primary: '#3498db',
  secondary: '#2ecc71',
  background: '#ffffff',
  text: '#000000',
  error: '#e74c3c',
  textSecondary: '#777777',
  border: '#eaeaea',
  disabled: '#d3d3d3',
  buttonBackground: '#007bff',
  buttonText: '#ffffff',
  buttonHoverBackground: '#0056b3',
  buttonHoverText: '#ffffff',
  focus: '#ffcc00',
  cardBackground: '#f8f9fa',
  navBackground: '#ffffff',
  primaryDark: '#2980b9',
  errorBackground: '#f8d7da',
  accent: '#ff6347', // Add appropriate color
};

const darkColors: Colors = {
  primary: '#3498db',
  secondary: '#2ecc71',
  background: '#000000',
  text: '#ffffff',
  error: '#e74c3c',
  textSecondary: '#777777',
  border: '#333333',
  disabled: '#555555',
  buttonBackground: '#007bff',
  buttonText: '#ffffff',
  buttonHoverBackground: '#0056b3',
  buttonHoverText: '#ffffff',
  focus: '#ffcc00',
  cardBackground: '#343a40',
  navBackground: '#000000',
  primaryDark: '#2980b9',
  errorBackground: '#f8d7da',
  accent: '#ff6347', // Add appropriate color
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  colors: lightColors,
};

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  colors: darkColors,
};

// Add this type declaration to ensure type safety
declare module 'styled-components' {
  export interface DefaultTheme {
    fontWeights: typeof baseTheme.fontWeights;
    fontSizes: typeof baseTheme.fontSizes;
    spacing: typeof baseTheme.spacing;
    borderRadius: typeof baseTheme.borderRadius;
    transitions: typeof baseTheme.transitions;
    breakpoints: typeof baseTheme.breakpoints;
    lineHeights: typeof baseTheme.lineHeights;
    sizes: typeof baseTheme.sizes;
    zIndex: typeof baseTheme.zIndex;
    colors: Colors;
  }
}
