import React, { useState, useCallback, memo } from 'react';
import {
  Facebook,
  GitHub,
  Instagram,
  Linkedin,
  Twitter,
} from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../../hooks/useTheme';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: ${({ theme }) => theme.spacing[8]} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const FooterContent = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing[4]};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const FooterTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.primary};
`;

const FooterLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  transition: color 0.3s ease;

  &:hover, &:focus {
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
    text-decoration: underline;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[4]};
`;

const SocialIcon = styled.a`
  color: ${({ theme }) => theme.colors.text};
  transition: color 0.3s ease;

  &:hover, &:focus {
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[4]};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const NewsletterInput = styled.input`
  padding: ${({ theme }) => theme.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  margin-right: ${({ theme }) => theme.spacing[2]};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryLight};
  }
`;

const NewsletterButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover, &:focus {
    background-color: ${({ theme }) => theme.colors.secondary};
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

const AlertMessage = styled.p`
  margin-top: ${({ theme }) => theme.spacing[2]};
  color: ${({ theme, status }) => (status === 'success' ? theme.colors.success : theme.colors.error)};
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[8]};
  padding-top: ${({ theme }) => theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const Footer: React.FC = () => {
  const { theme } = useTheme();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleNewsletterSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubscriptionStatus('idle');

    try {
      // Simulating an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Implement actual newsletter signup logic here
      console.log('Newsletter signup:', email);
      
      setSubscriptionStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Newsletter signup failed:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  }, [email]);

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>MEGAPROMPT.IO</FooterTitle>
          <p>Empowering Your Creativity, One Prompt at a Time</p>
          <SocialLinks>
            <SocialIcon href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <Facebook aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://X.com" target="_blank" rel="noopener noreferrer" aria-label="X">
              <Twitter aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <Instagram aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <Linkedin aria-hidden="true" />
            </SocialIcon>
            <SocialIcon href="https://github.com" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <GitHub aria-hidden="true" />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <nav aria-label="Footer Navigation">
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/projects">Projects</FooterLink>
            <FooterLink to="/gallery">Gallery</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </nav>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Legal</FooterTitle>
          <nav aria-label="Legal Navigation">
            <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
          </nav>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Newsletter</FooterTitle>
          <p>Stay updated with our latest projects and innovations.</p>
          <NewsletterForm onSubmit={handleNewsletterSubmit}>
            <NewsletterInput
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email for newsletter"
              disabled={isSubmitting}
            />
            <NewsletterButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Subscribing...' : 'Subscribe'}
            </NewsletterButton>
          </NewsletterForm>
          {subscriptionStatus === 'success' && (
            <AlertMessage role="alert" status="success">Successfully subscribed!</AlertMessage>
          )}
          {subscriptionStatus === 'error' && (
            <AlertMessage role="alert" status="error">Subscription failed. Please try again.</AlertMessage>
          )}
        </FooterSection>
      </FooterContent>
      <Copyright>
        © {new Date().getFullYear()} MEGAPROMPT.IO. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default memo(Footer);
