import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FaExclamationTriangle, FaSync } from 'react-icons/fa';
import { darkTheme } from '../../styles/theme';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
`;

const ErrorIcon = styled(FaExclamationTriangle)`
  font-size: 4rem;
  color: ${props => props.theme.colors.error};
  margin-bottom: 1rem;
`;

const ErrorHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  max-width: 600px;
`;

const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover, &:focus {
    background-color: ${props => props.theme.colors.secondary};
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const RefreshIcon = styled(FaSync)`
  margin-right: 8px;
`;

interface Props {
  children: ReactNode;
  fallbackUI?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    console.error('Uncaught error:', error, errorInfo);
  }

  private handleRefresh = () => {
    window.location.reload();
  };

  private renderErrorMessage() {
    const { error, errorInfo } = this.state;
    if (process.env.NODE_ENV === 'development' && error) {
      return (
        <>
          <ErrorMessage>{error.toString()}</ErrorMessage>
          {errorInfo && (
            <details style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem' }}>
              {errorInfo.componentStack}
            </details>
          )}
        </>
      );
    }
    return (
      <ErrorMessage>
        We're sorry for the inconvenience. Please try refreshing the page or contact support if the problem persists.
      </ErrorMessage>
    );
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallbackUI) {
        return this.props.fallbackUI;
      }
      return (
        <ThemeProvider theme={darkTheme}>
          <ErrorContainer role="alert">
            <ErrorIcon aria-hidden="true" />
            <ErrorHeading>Oops! Something went wrong.</ErrorHeading>
            {this.renderErrorMessage()}
            <RefreshButton onClick={this.handleRefresh}>
              <RefreshIcon aria-hidden="true" />
              Refresh Page
            </RefreshButton>
          </ErrorContainer>
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
