// src/components/common/Navigation/DropdownMenu.tsx
import React, { useCallback, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DropdownContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 10;
  background-color: ${({ theme }) => theme.colors.navBackground};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  min-width: 220px;
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => `${theme.colors.primary} ${theme.colors.navBackground}`};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.navBackground};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.colors.navBackground};
  }
`;

const DropdownLink = styled(motion(Link))`
  display: block;
  padding: 0.85rem 1.2rem;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: 0.95rem;
  font-weight: 500;

  &:hover, &:focus {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.navBackground};
    outline: none;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

interface Project {
  id: string;
  name: string;
}

interface DropdownMenuProps {
  isOpen: boolean;
  projects: Project[];
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ isOpen, projects, onMouseEnter, onMouseLeave }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onMouseLeave();
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      const menuItems = menuRef.current?.querySelectorAll('[role="menuitem"]');
      if (menuItems) {
        const currentIndex = Array.from(menuItems).indexOf(document.activeElement as Element);
        const nextIndex = event.key === 'ArrowDown'
          ? (currentIndex + 1) % menuItems.length
          : (currentIndex - 1 + menuItems.length) % menuItems.length;
        (menuItems[nextIndex] as HTMLElement).focus();
      }
    }
  }, [onMouseLeave]);

  useEffect(() => {
    if (isOpen) {
      const firstMenuItem = menuRef.current?.querySelector('[role="menuitem"]');
      if (firstMenuItem) (firstMenuItem as HTMLElement).focus();
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownContainer
          ref={menuRef}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onKeyDown={handleKeyDown}
          role="menu"
          aria-label="Projects"
        >
          {projects.map((project) => (
            <DropdownLink
              key={project.id}
              to={`/projects/${project.id}`}
              whileHover={{ x: 5 }}
              whileFocus={{ x: 5 }}
              transition={{ type: 'spring', stiffness: 300 }}
              tabIndex={isOpen ? 0 : -1}
              role="menuitem"
            >
              {project.name}
            </DropdownLink>
          ))}
        </DropdownContainer>
      )}
    </AnimatePresence>
  );
};

export default React.memo(DropdownMenu);
