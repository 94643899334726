import React from 'react';

import {
  Moon,
  Sun,
} from 'react-feather';

export const ROUTES = [
  { path: '/', label: 'Home' },
  { path: '/about', label: 'About' },
  { path: '/gallery', label: 'Gallery' },
  { path: '/contact', label: 'Contact' },
];

export const THEME_ICONS = {
  light: <Sun size={20} />,
  dark: <Moon size={20} />,
};
