import React, { createContext, ReactNode, useContext, useReducer, useMemo, useCallback } from 'react';

export interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  icon: string;
}

export interface GalleryItem {
  id: number;
  src: string;
  title: string;
  category: string;
  description?: string;
  comparisonSrc?: string;
}

interface User {
  id: number;
  name: string;
}

interface State {
  loading: boolean;
  error: string | null;
  user: User | null; // Added user state
}

type Action =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'SET_USER'; payload: User | null }; // Added user action

interface GlobalStateContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  setUser: (user: User | null) => void; // Added setUser function
}

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

const initialState: State = {
  loading: false,
  error: null,
  user: null, // Initial user state
};

const globalStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload }; // Handle user action
    default:
      return state;
  }
};

export const GlobalStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  const setLoading = useCallback((loading: boolean) => {
    dispatch({ type: 'SET_LOADING', payload: loading });
  }, []);

  const setError = useCallback((error: string | null) => {
    dispatch({ type: 'SET_ERROR', payload: error });
  }, []);

  const setUser = useCallback((user: User | null) => {
    dispatch({ type: 'SET_USER', payload: user });
  }, []);

  const contextValue = useMemo(() => ({ 
    state, 
    dispatch, 
    setLoading, 
    setError, 
    setUser // Include setUser in context value
  }), [state, setLoading, setError, setUser]);

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (): GlobalStateContextType => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
